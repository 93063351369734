<template>
  <v-container>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="quotesDetails"
        :loading="loading"
        class="elevation-1"
      >
        <template
        v-slot:[`item.createdAt`]="{ item }"
      >
        {{ getReadableDate(item.createdAt) }}
      </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { getAllQuotesOnRequest } from "@/services/admin";
import Icons from "../dashboard/component/Icons.vue";
import VComponent from "../../components/base/VComponent.vue";

export default {
  components: { Icons, VComponent },
   data: () => ({
      quotesDetails: [],
      headers: [
        {
          text: "Driver",
          align: "start",
          sortable: false,
          value: "driver_name",
        },
        {
          text: "ETA",
          align: "start",
          sortable: false,
          value: "eta",
        },
        {
          text: "Price",
          align: "start",
          sortable: false,
          value: "price",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
        },{
          text: "CreatedAt",
          align: "start",
          sortable: false,
          value: "createdAt",
        }
      ],
      
    }),
computed: {
    customDetails() {
        
      return {
        driver_name: `${this.quotesDetails.driverId.firstName}  ${this.quotesDetails.driverId.surName}`,
        eta:this.quotesDetails.ETA.time ,
        price: this.quotesDetails.price,
        status: this.quotesDetails.status,
        createdAt: this.quotesDetails.createdAt,
      };
    },
  },
mounted() {
    getAllQuotesOnRequest({
    "requestId": this.$route.params.id
    }).then((res) => {
        let quotesResult = res.data.quotes;
        quotesResult.forEach(element => {
         let row =    {
        driver_name: `${element.driverId.firstName}  ${element.driverId.surName}`,
        eta:element.ETA.time ,
        price: element.price,
        status: element.status,
        createdAt: element.createdAt,
      }
      this.quotesDetails.push(row)
   });
   console.log(this.quotesDetails,"sfsadfsa")
     
    });
    
  },
  methods: {
  
    getReadableDate(date) {
      const d = new Date(date).toUTCString();
      return date.substr(0, 7);
    },
    goToQuotes(id) {
      this.$router.push({ name: "Quote list", params: { id: id } });
    },
 
  },





};


</script>

